.book-card {
  height: 450px;
  overflow: hidden;
}

.book-card h3{
  max-height: 58px; 
  text-overflow: ellipsis;
  overflow: hidden;
}

.book-card img{
  width: 100%;
  height: 200px;
  object-fit: contain;
}
