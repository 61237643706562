body {
  background-color: #f5f5f5;
}

.ant-input-affix-wrapper, .ant-input {
  background-color: #fefefe !important;
}

.app {
  max-width: 1600px;
  margin: 0 auto;
}