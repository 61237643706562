.book-cards-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  padding: 20px;
}

.load-more{
  margin: 30px auto;
  display: block;
}

/* Media query for smaller screen sizes */
@media (max-width: 1000px) {
  .book-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 800px) {
  .book-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 500px) {
  .book-cards-container {
    grid-template-columns: repeat(1, 1fr);
  }
}