.footer {
  margin: '2%';
  padding: "2%";
  text-align: center;
}

.company {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 20px;
}