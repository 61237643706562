.header{
    background-color:#27024B;
    padding-bottom: 1rem;
}

.search{
    margin:0px 2% 0px 50%;
    padding: 0 0 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.ant-input-affix-wrapper,
.ant-input  {
    background-color:#E7F8FC;
}

.title a{
    align-items:flex-start;
    width: 90%;
    color:#F3ECEC;
}


