/* WishList.css */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px; /* Add padding to create margins from edges */
}

.title2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap; /* Add this line to wrap cards into multiple rows */
  justify-content: center; /* Center cards horizontally */
  gap: 20px; /* Add gap between cards */
}

.card {
  width: 300px;
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card p {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.card:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}

.buyLink {
  color: #1890ff;
  font-weight: bold;
  width: 100%; /* Set a fixed width for the link container */
  word-wrap: break-word; /* Allow the link text to wrap if it exceeds the container width */
}