.signUpForm form {
    margin-top: 5rem;
    margin-left: 35rem;
    margin-bottom: 5rem;
    /* max-width: 00px; */
}

.ant-form-item-label {
    max-width: 800px;
    color: aqua;
}

.ant-input{
    max-width: 400px;
}

.ant-input-password{
    width:auto;
}

.button div{
    align-items: center;
    
}

.invalidMessage p{
    margin:0;
    color:red;
}

.registrationSuccessContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
    min-height: 200px; /* Adjust this value to control the minimum height of the container */
  }
  
  .registrationSuccessCard {
    width: 400px;
    text-align: center;
    padding: 24px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }