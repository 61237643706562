.review {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 15px;
  background-color: #fdfdfd;
  border: 1px solid #cecece;
  margin: 10px 0;
}
.review .review__img {
  margin: 10px;
  margin-right: 25px;
  width: 75px;
  height: 75px;
}

.review .review__content {
  display: block;
  width: 100%;
  
}